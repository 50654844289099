import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Get a Quote | Dark Horse CPAs"
        description="Get a quote from Dark Horse CPAs today! We're a top accounting & tax firm serving small businesses & individuals nationwide."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-[#E9F3F5] pt-16 pb-40 md:pb-80 md:pt-32">
        <div className="container">
          <header className="mx-auto mb-10 max-w-[720px] text-center md:mb-14">
            <h1 className="heading-two">Choose Your Path</h1>
            <p className="md:text-xl">
              To get an estimate without submitting your tax return and
              documents, click{" "}
              <span className="whitespace-nowrap">
                <i>Get an Estimate</i>
              </span>{" "}
              below. To receive a free initial consultation with an exact quote,
              click{" "}
              <span className="whitespace-nowrap">
                <i>Book Consultation</i>
              </span>{" "}
              below.
            </p>
          </header>

          <div className="mx-auto grid max-w-[808px] gap-y-8 text-center md:grid-cols-2 md:gap-x-5 lg:gap-x-10">
            <div className="rounded-4xl bg-white p-6 px-6 shadow-4xl md:p-8">
              <div className="relative h-full">
                <div className="mb-4 text-3xl font-bold text-primary-900">
                  Book a Quoting Call
                </div>
                <p className="mb-4 text-sm">
                  Provide us with some basic information and we'll schedule a
                  meeting to talk about your situation. A Dark Horse CPA will
                  provide you with a quote for services.
                </p>
                <div className="bottom-12 mb-5 flex w-full items-center justify-center space-x-2 md:absolute">
                  <i className="fa-light fa-clock text-xl text-gray-400"></i>
                  <span className="text-sm font-medium uppercase tracking-wide text-gray-400">
                    2 min
                  </span>
                </div>
                <div className="bottom-0 w-full md:absolute">
                  <ButtonSolid
                    href="/request-a-quote/"
                    text="Get an Estimate"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
            <div className="rounded-4xl bg-white p-6 px-6 shadow-4xl md:p-8">
              <div className="mb-4 text-3xl font-bold text-primary-900">
                Book a Strategic Consult
              </div>
              <p className="mb-4 text-sm">
                In exchange for providing us with more detailed information and
                your prior year tax return, we'll provide you with an estimate
                and a consultation scheduling link to discuss your unique needs
                with one of our CPAs.
              </p>
              <div className="mb-5 flex items-center justify-center space-x-2">
                <i className="fa-light fa-clock text-xl text-gray-400"></i>
                <span className="text-sm font-medium uppercase tracking-wide text-gray-400">
                  7 min
                </span>
              </div>
              <ButtonSolid
                href="/book-a-consultation/"
                text="Book Consultation"
                className="w-full"
                altStyle={3}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
